import React from 'react';
import MapComponent from '../Components/MapComponent'; 

function Contact() {
  return (
    <div className='flex flex-row justify-center items-start bg-gray-100 p-4'>
      <div className='mr-44' style={{ width: '400px' }}>
        <MapComponent />
      </div>
      <div className='flex flex-col'>
        <h1 className='text-2xl font-medium py-2'>
          Contact
        </h1>
        <p className='text-m font-medium'>New Light Korean Church </p>
        <p className='text-m font-medium'>30 Main Street</p>
        <p className='text-m font-medium'>Waltham, MA 02451</p>
        <div className='py-8'>
        </div>
        <p><span className='font-bold'>E:</span> newlightchurchem@gmail.com</p>
        <p><span className='font-bold'>Pastor Josh:</span> joshuajoh94@gmail.com</p>
        <div className='py-4'/>
        <a href="http://www.newlightkc.org/" target="_blank" rel="noopener noreferrer" className='font-medium'>Our Korean Congregation website</a>
      </div>
    </div>
  )
}

export default Contact;
