import React from 'react';
import church from "../Assets/church.png";
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="bg-gray-100">
      <div className="flex flex-col items-center px-12 border-white">
        <div className="w-full md:w-4/5">
          <div className="bg-white h-1 w-full mb-4" /> 
          <img src={church} alt="church" className="w-full h-auto rounded-xl mb-4" />
          <div className="bg-white h-1 w-full mb-4" /> 
          <div className="flex flex-row items-center text-center">
            <h1 className="text-slate-500 font-thick text-6xl">
              A Church For Our City
            </h1>
            <div className="w-full h-100 overflow-auto max-w-prose mx-auto">
              <p className="text-slate-700 text-2xl font-thin leading-loose mt-4">
                We are the English Speaking congregation of New Light Korean Church.
                NLKC EM is a vibrant and growing community in Waltham, MA.
                Our worship services are every Sunday at 9:30AM, in the main sanctuary.
              </p>
              <Link to='SundayService' >
                <button className="mt-4 bg-white g border-black font-thin py-3 px-8 rounded-lg text-xl">
                  See How You Can Be Plugged In!
                </button>
              </Link>
            </div>
          </div>
          <div className="bg-white h-1 w-full mb-4 mt-4" /> 
            <h1 className="text-slate-500 font-thin text-4xl mb-10">
            Upcoming Events
            </h1>
        </div>
      </div>
    </div>
  );
};

export default Home;
