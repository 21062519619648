import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '500px',
  height: '600px'
};

const center = {
  lat: 42.37580141212075,  // Replace with your church's latitude
  lng: -71.23942780258214  // Replace with your church's longitude
};

const mapStyles = [
  {
    "featureType": "landscape",
    "elementType": "labels",
    "stylers": [
        {
            "visibility": "off"
        }
    ]
},
{
    "featureType": "transit",
    "elementType": "labels",
    "stylers": [
        {
            "visibility": "off"
        }
    ]
},
{
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
        {
            "visibility": "off"
        }
    ]
},
{
    "featureType": "water",
    "elementType": "labels",
    "stylers": [
        {
            "visibility": "off"
        }
    ]
},
{
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
        {
            "visibility": "off"
        }
    ]
},
{
    "stylers": [
        {
            "hue": "#00aaff"
        },
        {
            "saturation": -100
        },
        {
            "gamma": 2.15
        },
        {
            "lightness": 12
        }
    ]
},
{
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
        {
            "visibility": "on"
        },
        {
            "lightness": 24
        }
    ]
},
{
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
        {
            "lightness": 57
        }
    ]
}
];

const handleMarkerClick = () => {
  const address = "730 Main Street, Waltham, MA 02451";
  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  window.open(url, 'https://www.google.com/maps?sll=42.375673,-71.239443&q=730+Main+Street+Waltham,+MA+02451,+United+States&z=12');
}

const MapComponent = () => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyA_mM9e2rfuzRBS5meTpCkIAmSpWVXJRbU">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        options={{ styles: mapStyles }} 
      >
        <Marker position={center} onClick={handleMarkerClick} />
      </GoogleMap>
    </LoadScript>
  )
}

export default MapComponent;
