import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import Sermons from './Pages/Sermons';
import Reflections from './Pages/Reflections';
import OurBeliefs from './Pages/OurBeliefs';
import Vision from './Pages/Vision';
import HouseChurch from './Pages/HouseChurch';
import CollegeMinistry from './Pages/CollegeMinistry';
import CoreValues from './Pages/CoreValues';
import Footer from './Components/Footer';
import Leadership from './Pages/Leadership';
import Sunday from './Pages/Sunday';
import ScrollToTop from './Components/ScrollToTop';
import Calendar from './Pages/Calendar';

const App = () => {
    return (
        <>
            <ScrollToTop />
            <Navbar className="border-b border-white bg-gray-100"/>
            <div style={{ marginTop: '-1px' }}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Beliefs" element={<OurBeliefs />} />
                    <Route path="/Contact" element={<Contact />} />
                    <Route path="/Sermons" element={<Sermons />} />
                    <Route path="/Vision" element={<Vision />} />
                    <Route path="/CoreValues" element={<CoreValues />} />
                    <Route path="/HouseChurch" element={<HouseChurch />} />
                    <Route path="/CollegeMinistry" element={<CollegeMinistry />} />
                    <Route path="/Reflections" element={<Reflections />} />
                    <Route path="/Leadership" element={<Leadership />} />
                    <Route path="/SundayService" element={<Sunday/>} />
                    <Route path="/Calendar" element={<Calendar/>} />
                </Routes>
            </div>
            <div style={{ marginBottom: '-100px' }}>
                <Footer />
            </div>
        </>
    );
};

export default App;

