import React from 'react';
import PJ from '../Assets/PJ.png';

const Leadership = () => {
    return (
        <div className='bg-gray-100 flex flex-col'>
            <div className="bg-white h-1 w-3/4 mx-auto my-4"></div> 
            <div className="flex flex-row items-center p-8 space-x-8 px-12">
                <div className="hidden lg:block w-96 bg-gray-50 h-full">
                </div>
                <img src={PJ} alt='Pastor Josh' className='w-1/3 rounded-sm'/>
                <div>
                    <h1 className="text-m font-light mb-4"><span className="font-black">Lead pastor:</span> Rev. Joshua Oh</h1>
                    <p className="font-light mt-2 mb-4">Pastor Josh has been serving on the pastoral staff of New Light Korean Church since the Fall of 2016. He graduated with his M.Div from Gordon-Conwell Theological Seminary and is currently pursuing a Th.M at Trinity Evangelical Divinity School. He is currently ordained in the KAPC. </p>
                    <p className="font-light mb-4">In his free time, he enjoys reading, playing sports, and watching movies (even if it means having to go to the theater alone). </p>
                    <p className="font-light mb-4">He is happily married to Janet, and the two of them welcomed their son Seth, in March of 2022.</p>
                </div>
                <div className="hidden lg:block w-64 bg-gray-200 h-full">  
                </div>
            </div>
            <div>

            </div>
        </div>
    );
};

export default Leadership;

