
import React from 'react';

const CoreValues = () => {
  return (
    <div className="bg-gray-100">
     <div className="hidden lg:block w-10 bg-white h-full">
            </div>
      <div className="flex flex-row justify-center h-full w-full md:w-4/5">
        <div className="text-slate-500 font-thick text-4xl ml-56 "> {/* Adjusted margins */}
          Gospel
        </div>
        <div className="w-full max-w-prose mx-auto">
          <p className="text-slate-700 text-2xl font-thin leading-loose mt-4">
            We endeavor to make the Gospel the foundation and substance of all that we do.
            The Gospel is the good news of salvation for weary sinners. We believe that the Gospel
            proclaims Jesus as the only way of salvation, and that we receive this salvation through faith by grace.
          </p>
          <p className="text-slate-700 text-2xl font-thin leading-loose mt-4">
            The Gospel also hastens believers to live out their lives in the way that Jesus did. We want to be a church
            that is passionate about proclaiming the good news of salvation to all, living out the love and justice of
            God in our communities, and witnessing people be transformed through discipleship.
          </p>
        </div>
        
      </div>
      <div className="bg-white h-1 w-full mb-4 mt-4" /> {/* White bar below Navbar */}
      
      <div className="text-slate-500 font-thick text-4xl ml-56 mr-16"> {/* Adjusted margins */}
      Communal
        </div>
        <div className="w-full max-w-prose mx-auto">
          <p className="text-slate-700 text-2xl font-thin leading-loose mt-4">
            New Light seeks to be a church that serves its community. We hope to be active in serving the city of Waltham 
            through various means. 
          </p>
          <p className="text-slate-700 text-2xl font-thin leading-loose mt-4">
            We seek to be an extension of Christ’s heart for Waltham and its residents. 
          </p>
        </div>
    </div>
  );
};

export default CoreValues;

