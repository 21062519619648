import React from 'react';

const Calendar = () => {
    return (
        <div className='bg-gray-100 flex justify-center items-center min-h-screen'>
            <iframe 
                title="Google Calendar"
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23A79B8E&ctz=America%2FNew_York&showNav=1&src=Y19jZGIyZTczYzIzZDU0OWZkNjJiYzYzZTQ1MDcyMjA1MjU3NGU2MzgzZWFkM2RmZTlhNjVlNjM2ZmIwMDdjZWQzQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%238E24AA" 
                style={{borderWidth: 0}} 
                width="800" 
                height="600" 
                frameBorder="0" 
                scrolling="no">
            </iframe>
        </div>
    );
}
  
export default Calendar;
