import React from 'react'

const Vision = () => {
  return (
      <div className="flex items-center justify-center min-h-[calc(100vh-30px)] mt-[-100px] text-4xl font-medium text-center p bg-gray-100 px-40">
        Our vision is to know, share, and live out the heart of Jesus for the Greater Boston area. 
      </div>
  )
}


export default Vision


