import React from 'react'
import cm from '../Assets/cm.png'

const CollegeMinistry = () => {
  return (
    <div className='flex flex-col justify-center items-center bg-gray-100'>
      <img src={cm} alt='college ministry' className="w-3/4 h-auto rounded-xl mb-4"/>
      <div className="w-3/4">
        <div className="mb-4 font-light text-2xl">
          At NLKC, we have a growing college ministry that represents various campuses in the Boston and Greater Boston area. We have monthly Friday gatherings on the first Friday of every month, as well as small groups after service every Sunday. We provide rides for our students, and we would love to see our ministry grow. 
        </div>
        <div className="mb-4 font-light text-2xl">
          If you are a college student interested in joining our church please reach out to Pastor Josh!
        </div>
        <div className='py-12'>
        </div>
      </div>

    </div>
  )
}

export default CollegeMinistry;
