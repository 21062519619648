import React from 'react';
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <div className="bg-gray-100 p-8">
      <div className="bg-white h-1 w-3/4 mx-auto my-4"></div> 
      <div className="max-w-4xl mx-auto flex justify-between items-center">
        <div className="text-slate-500">
          730 Main Street Waltham, MA 02451
        </div>
        <div className="flex space-x-4">
          {/* Social Media Icons */}
          <a href="https://www.instagram.com/newlightboston/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="text-pink-400 hover:text-blue-300 transition duration-300 mx-2" />
          </a>
          <a href="https://www.facebook.com/NewLightBoston" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="text-blue-500 hover:text-blue-300 transition duration-300 mx-2" />
          </a>
          <a href="https://www.youtube.com/channel/UCn-GM7uravj5YiidQRja6VQ" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="text-red-500 hover:text-red-300 transition duration-300 mx-2" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
