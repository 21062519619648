import React from 'react'
import Sermon from '../Assets/Sermon.png'

const Sermons = () => {
  // Replace with your YouTube channel ID
  const channelID = 'YOUR_YOUTUBE_CHANNEL_ID';
  
  return (
    <div className='bg-gray-100 flex flex-col justify-center items-center'>
      <div className='w-3/4'>
        <img src={Sermon} alt='Bible' className="h-auto rounded-xl mb-4"/>
        <div className='font-extralight text-base italic'>
          *In-Person gatherings start on August 2, 2020.
        </div>
        <div className='py-10'>

        </div>
        <div className='flex justify-center items-center text-5xl font-light'>
          Service Times: Sundays at 11:30 AM
        </div>
        <div className="my-4 flex justify-center">
          <iframe 
            width="560" 
            height="315" 
            src={`https://www.youtube.com/embed/live_stream?channel=${channelID}`} 
            title="YouTube live stream" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </div>
  )
}

export default Sermons
