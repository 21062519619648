import React from 'react'
import Sun from '../Assets/sunday.png'

const Sunday = () => {
  return (
    <div className='bg-gray-100'>
        <div className="flex flex-col items-center w-2/3 mx-auto">
            
            <img src={Sun} alt='SundayService' className="mb-4"/>
            <div className="flex flex-col w-full">
                <div className="flex flex-col text-left font-extralight w-full pr-4 py-8">
                    <h1 className='text-2xl'>
                        COVID-19
                    </h1>
                    <p className='text-m italic tracking-wide'>
                        In order to ensure the safety of our members and visitors of New Light Korean Church, we highly recommend all members and visitors to wear a mask for the duration of the worship service. Thank you.
                    </p>
                </div>
                <div className="flex flex-col text-left w-full pr-4 py-4">
                    <h1 className='text-3xl font-medium'>
                        Worship Service:
                    </h1>
                    <p className='text-2xl font-light tracking-wide'>
                    At New Light, we seek to glorify God through the songs that we sing, the teaching of the Scriptures, as well as the fellowship that we have. We welcome all believers and skeptics to our worship service. We are a small but growing ministry, so we encourage all people of all ages and life stages to come and find a home in us! 
                    </p>
                </div>
                <div className="flex flex-col text-left w-full pr-4 py-4">
                    <p className='text-m font-light'>
                    We currently worship in the Main Sanctuary of our church at 9:30AM. The entrance is on the side of the building, and you may park in our parking lot, as well as on the street.
                    </p>
                </div>
                <div className="flex flex-col text-left w-full pr-4 py-4">
                    <h1 className='text-3xl font-semibold'>
                        Children’s Ministry
                    </h1>
                    <p className='text-2xl font-light tracking-wide'>
                        New Light has simultaneous Sunday School services starting at 11:30AM.  We have a nursery to toddler group where the little ones can be taken care of as well.  Please feel free to bring your infants into the English Service to worship with us!
                    </p>
                </div>
                <div className="flex flex-col text-left w-full pr-4 py-4">
                    <h1 className='text-3xl font-semibold'>
                        Youth Group Ministry
                    </h1>
                    <p className='text-2xl font-light tracking-wide'>
                        Our youth group worships in the youth group chapel at 11:30AM every Sunday! They also meet every Saturday at 7PM.
                    </p>
                </div>
                
            </div>
            <div className='py-8'>
            </div>
        </div>
    </div>
  )
}

export default Sunday
