import React from 'react';
import Community from '../Assets/Community.jpeg';

const HouseChurch = () => {
    return (
        <div className='bg-gray-100 flex flex-col'>
            <div className="flex flex-row items-center p-8 space-x-8 px-12">
                <div className="hidden lg:block w-96 bg-gray-50 h-full"></div>
                <div className="flex flex-col w-12/12">
                    <img src={Community} alt='Community' className="w-full h-auto rounded-xl mb-4"/>
                    <div className='text-slate-700 text-2xl font-thin leading-loose'>
                        We meet weekly for our House Church gatherings.
                        House Church is a time for everyone to take a break from their busy weeks and enjoy fellowship with another through dinner, worship, and small group discussions.
                    </div>
                    <div className='text-slate-700 text-2xl font-thin leading-loose'>
                        We welcome all people, whether you are a believer or a skeptic, to get plugged into our House Church gatherings! 
                    </div>
                    <div className='text-slate-700 text-2xl font-thin leading-loose'>
                        For more info please contact Pastor Josh.
                    </div>

                </div>
                <div className="hidden lg:block w-64 bg-gray-200 h-full"></div>
            </div>
        </div>
    );
};

export default HouseChurch;
