import React from 'react'
import Beliefs from "../Assets/Beliefs.png"

const OurBeliefs = () => {
  return (
    <div className="flex flex-col items-center px-12 border-white mx-auto bg-gray-100">
        <div className="w-3/4">
            <div className="bg-white h-1 w-full mx-auto my-4"></div> 
            <img src={Beliefs} alt="Our Beliefs" className="w-full h-auto rounded-xl mb-4 py-8"/> 
            <div className="bg-white h-1 w-full mx-auto my-4"></div> 
            <div className="text-left">
                <h1 className='text-slate-600 font-semibold text-3xl'>
                    Core Beliefs
                </h1>
                <ul className="list-disc text-slate-600 text-2xl font-light leading-tight mt-2 pl-20 py-4 space-y-4">
                    <li>
                        The centrality and authority of Scripture.
                    </li>
                    <li>
                        The Trinitarian Godhead.
                    </li>
                    <li>
                        The fallen nature of man.
                    </li>
                    <li>
                        Salvation in Christ alone, by faith through grace alone.
                    </li>
                    <li>
                        The Second Coming of Christ.
                    </li>
                </ul>
                <h2 className='text-slate-600 font-semibold text-3xl'>
                    Distinctives
                </h2>
                <ul className='list-disc text-slate-600 text-2xl font-light leading-tight mt-2 pl-20 space-y-4'>
                    <li>
                        We are a member of the KAPC (Korean-American Presbytery Church). Therefore, we adhere to the tenets of Reformed Theology and Presbyterian governance. 
                    </li>
                    <li>
                        For a detailed insight into Reformed theology, we recommend the Westminster Confession of Faith as well as the Westminster Catechism (shorter and longer). We also recommend the Heidelberg Catechism. 
                    </li>
                    <li>
                        We also adhere to the traditional beliefs stated in the Apostles’ Creed, Nicene Creed, Athanasian Creed, and Chalcedonian Creed. 
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default OurBeliefs;
