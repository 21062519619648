import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NLKCLogo from '../Assets/NLKCLogo.png';

const Navbar = () => {
    const [showAboutDropdown, setShowAboutDropdown] = useState(false);
    const [showConnectDropdown, setShowConnectDropdown] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div className="flex justify-center border-b border-white bg-gray-100">
            <div className="hidden lg:block w-10 bg-white h-full"></div>
            <div className="flex flex-col lg:flex-row items-center justify-between w-full max-w-screen-lg px-4 py-2">
                <div className="text-center mb-4 lg:mb-0 flex flex-col items-center">
                    <button className="lg:hidden px-3 py-2 border rounded mb-2" onClick={() => setShowMenu(!showMenu)}>
                        MENU
                    </button>
                    <Link to="/">
                        <img src={NLKCLogo} alt="Church Logo" 
                            className="w-32 h-32 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-36 lg:h-30 rounded-xl"/>
                    </Link>
                </div>
                <div className={`lg:flex ${showMenu ? 'flex' : 'hidden'} flex-col lg:flex-row`}>
                    <ul className="flex space-x-4 flex-col lg:flex-row">
                        <li>
                            <Link className="text-black text-sm font-thin" to="/">
                                HOME
                            </Link>
                        </li>
                        <li className="relative" 
                             onMouseEnter={() => setShowAboutDropdown(true)} 
                             onMouseLeave={() => setShowAboutDropdown(false)}
                             onClick={() => setShowAboutDropdown(!showAboutDropdown)}>
                            <button className="text-black text-sm font-thin">ABOUT US</button>
                            {(showAboutDropdown || (window.innerWidth >= 1024 && showAboutDropdown)) && (
                                <div className="absolute left-0 w-64">
                                    <ul className="flex flex-col bg-gray-50 text-black font-thin">
                                        <li><Link to="/Vision">Vision</Link></li>
                                        <li><Link to="/Beliefs">Our Beliefs</Link></li>
                                        <li><Link to="/CoreValues">Core Values</Link></li>
                                        <li><Link to="/Leadership">Leadership</Link></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li className="relative" 
                             onMouseEnter={() => setShowConnectDropdown(true)} 
                             onMouseLeave={() => setShowConnectDropdown(false)}
                             onClick={() => setShowConnectDropdown(!showConnectDropdown)}>
                            <button className="text-black text-sm font-thin">CONNECT</button>
                            {(showConnectDropdown || (window.innerWidth >= 1024 && showConnectDropdown)) && (
                                <div className="absolute left-0 w-64">
                                    <ul className="flex flex-col bg-gray-50 text-black font-thin">
                                        <li><Link to="/SundayService">Sunday Service</Link></li>
                                        <li><Link to="/HouseChurch">House Church Groups</Link></li>
                                        <li><Link to="/CollegeMinistry">College Ministry</Link></li>
                                        <li><Link to="/Calendar">Calendar</Link></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li>
                            <Link className="text-black text-sm font-thin" to="/Contact">CONTACT</Link>
                        </li>
                        <li>
                            <Link className="text-black text-sm font-thin" to="/Sermons">SERMONS</Link>
                        </li>
                        <li>
                            <Link className="text-black text-sm font-thin" to="/Reflections">REFLECTIONS</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="hidden lg:block w-8 bg-gray-200 h-full"></div>
        </div>
    );
};

export default Navbar;
